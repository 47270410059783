import { graphql } from 'gatsby'
import PropTypes from 'prop-types'
import React from 'react'
import Breadcrumbs from '../components/Breadcrumbs'
import Content, { HTMLContent } from '../components/Content'
import GiftAidForm from '../components/GiftAidForm'
import Layout from '../components/Layout'
import PageTitle from '../components/PageTitle'
import PreviewCompatibleImage from '../components/PreviewCompatibleImage'
import TitleImage from '../components/TitleImage'

export const GeneralPageTemplate = ({ title, image, content, contentComponent, location }) => {
  const PageContent = contentComponent || Content

  return (
    <React.Fragment>
      {!!image ? (
        <TitleImage title={title} image={image.childImageSharp.fluid} styles={{"backgroundPosition": "center"}}/>
      ) : null }
      <section className="section section--gradient">
        <div className="container">
          <Breadcrumbs pathname={location?.pathname}/>
          <div className="section">
            <div className="content">
              <div className="columns">
                <div className="column is-10 is-offset-1">
                  {!image ? (
                    <PageTitle title={title}/>
                  ) : null }
                  <PageContent className="content" content={content} />
                  {title === "Our Achievements" ? (
                    <PreviewCompatibleImage imageInfo={{image : '/img/timeline_2020.jpg'}} styles={{ 'maxWidth': "1200px", 'width': "100%"}} />
                  ) : null }
                  {title === "Gift Aid" ? (
                    <GiftAidForm />
                  ) : null }
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

GeneralPageTemplate.propTypes = {
  title: PropTypes.string.isRequired,
  image: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
  content: PropTypes.string,
  contentComponent: PropTypes.func,
}

const GeneralPage = ({ data, location }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <GeneralPageTemplate
        title={post.frontmatter.title}
        image={post.frontmatter.image}
        content={post.html}
        contentComponent={HTMLContent}
        location={location}
      />
    </Layout>
  )
}

GeneralPage.propTypes = {
  data: PropTypes.object.isRequired,
}

export default GeneralPage

export const generalPageQuery = graphql`
  query GeneralPage($id: String!) {
    markdownRemark(id: { eq: $id }) {
      html
      frontmatter {
        title
        image {
          childImageSharp {
            fluid(maxWidth: 2048, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
