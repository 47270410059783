import React from 'react';
import Recaptcha from "react-google-recaptcha";
import { handleSubmit, recaptchaResponseKeyName, RECAPTCHA_KEY } from "../utils/forms";

const formKeys = {
  name: "name",
  email: "email",
  address: "address",
  postcode: "postcode",
  currentDate: "currentDate",
  donationDate: "donationDate",
  donationOnDate: "donationOnDate",
  pastDonations: "pastDonations",
  futureDonations: "futureDonations",
  donationDeclarations: "donationDeclarations",
  acknowledgement: "acknowledgement"
}

export default class GiftAidForm extends React.Component {
  constructor() {
    super()
    this.state = { 
      [formKeys.donationDate] : new Date(),
      [formKeys.donationOnDate] : true,
      [formKeys.pastDonations] : true,
      [formKeys.futureDonations] : true,
      [formKeys.currentDate] : new Date(),
      [formKeys.donationDeclarations] : "",
    }

    this.parseDateToISOString.bind(this);
    this.parseDataAndSubmit.bind(this);
    this.canSubmit.bind(this);
  }

  parseDateToISOString(date) {
    return date?.toISOString().slice(0, 10);
  }

  handleTextInputChange = (e) => {
    this.setState({ [e.target.name]: e.target.value })
  }

  handleCheckboxChange = (e) => {
    this.setState({ [e.target.name]: e.target.checked })
  }

  handleDateChange = (e) => {
    this.setState({ [e.target.name]: e.target.valueAsDate })
  }

  handleRecaptcha = (value) => {
    this.setState({ [recaptchaResponseKeyName]: value });
  };

  canSubmit() {
    return this.state[formKeys.acknowledgement] && 
    ( this.state[formKeys.donationOnDate] ||
      this.state[formKeys.pastDonations] || this.state[formKeys.futureDonations] );
  }

  parseDataAndSubmit(e) {
    let data = {
      [formKeys.name] : this.state[formKeys.name],
      [formKeys.email] : this.state[formKeys.email],
      [formKeys.address] : this.state[formKeys.address],
      [formKeys.postcode] : this.state[formKeys.postcode],
      [formKeys.currentDate] : this.state[formKeys.currentDate].toLocaleDateString(),
      [formKeys.donationDeclarations]: this.parseDonationDeclarations(),
      [formKeys.acknowledgement]: this.state[formKeys.acknowledgement],
      [recaptchaResponseKeyName]: this.state[recaptchaResponseKeyName],
      isValidated: false,
    };

    handleSubmit(e, data)
  }

  parseDonationDeclarations() {
    let declarations = '';
    if (this.state[formKeys.donationOnDate]) {
      declarations += `Donation made on ${this.state[formKeys.donationDate].toLocaleDateString()}. `
    }
    if (this.state[formKeys.pastDonations]) {
      const currentDate = this.state[formKeys.currentDate];
      const fourYearsAgo = new Date(currentDate.getFullYear() - 4, currentDate.getMonth(), currentDate.getDate());
      declarations += `All donations made between ${fourYearsAgo.toLocaleDateString()} and ${this.state[formKeys.currentDate].toLocaleDateString()}. `
    }
    if (this.state[formKeys.futureDonations]) {
      declarations += `All donations made from ${this.state[formKeys.currentDate].toLocaleDateString()} onwards.`
    }
    return declarations;
  }

  render() {
    return (
      <form
        name="gift-aid"
        method="post"
        action="/get-involved/donate/gift-aid/thanks"
        data-netlify="true"
        data-netlify-honeypot="bot-field"
        onSubmit={(e) => this.parseDataAndSubmit(e)}
        data-netlify-recaptcha="true"
      >
        {/* The `form-name` hidden field is required to support form submissions without JavaScript */}
        <input type="hidden" name="form-name" value="contact" />
        <div hidden>
          <label>
            Don’t fill this out:{' '}
            <input name="bot-field" onChange={this.handleTextInputChange} />
          </label>
        </div>
        <div className="field">
          <label className="label" htmlFor={formKeys.name}>
            Your name
          </label>
          <div className="control">
            <input
              className="input"
              type={'text'}
              name={formKeys.name}
              onChange={this.handleTextInputChange}
              id={formKeys.name}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={formKeys.email}>
            Email
          </label>
          <div className="control">
            <input
              className="input"
              type="email"
              name={formKeys.email}
              onChange={this.handleTextInputChange}
              id={formKeys.email}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={formKeys.address}>
            Address
          </label>
          <div className="control">
            <textarea
              className="textarea"
              name={formKeys.address}
              onChange={this.handleTextInputChange}
              id={formKeys.address}
              required={true}
            />
          </div>
        </div>
        <div className="field">
          <label className="label" htmlFor={formKeys.postcode}>
            Postcode
          </label>
          <div className="control">
            <input
              className="input"
              type={'text'}
              name={formKeys.postcode}
              onChange={this.handleTextInputChange}
              id={formKeys.postcode}
              required={true}
            />
          </div>
        </div>
        <br />
        <p className="has-text-weight-bold is-size-5">
          I want BEHT to treat the following as Gift Aid Donations (select all which apply):
        </p>
        <div className="field">
          <label class="label checkbox" htmlFor={formKeys.donationOnDate}>
            <input 
              className="mr-1"
              type="checkbox"
              name={formKeys.donationOnDate}
              onChange={this.handleCheckboxChange}
              id={formKeys.donationOnDate}
              checked={this.state[formKeys.donationOnDate]}
            />
            <span>The donation made on the date: </span>
          </label>
          <input
            type="date"
            id={formKeys.donationDate}
            name={formKeys.donationDate}
            onChange={this.handleDateChange}
            value={this.parseDateToISOString(this.state[formKeys.donationDate])}
            max={this.parseDateToISOString(this.state[formKeys.donationDate])}
            placeholder="yyyy-mm-dd" //renders as a text input with placeholder when datepicker is not supported 
          />
        </div>
        <div className="field">
          <label class="label checkbox" htmlFor={formKeys.pastDonations}>
            <input 
              className="mr-1"
              type="checkbox"
              name={formKeys.pastDonations}
              onChange={this.handleCheckboxChange}
              id={formKeys.pastDonations}
              checked={this.state[formKeys.pastDonations]}
            />
            All donations made in the past 4 years
          </label>
        </div>
        <div className="field">
          <label class="label checkbox" htmlFor={formKeys.futureDonations}>
            <input 
              className="mr-1"
              type="checkbox"
              name={formKeys.futureDonations}
              onChange={this.handleCheckboxChange}
              id={formKeys.futureDonations}
              checked={this.state[formKeys.futureDonations]}
            />
            All future donations made from the date of this declaration until further notice
          </label>
        </div>
        <div className="field mt-3">
          <label class="label checkbox is-italic has-text-weight-semibold" htmlFor={formKeys.acknowledgement}>
            <input 
              className="mr-1"
              type="checkbox"
              name={formKeys.acknowledgement}
              onChange={this.handleCheckboxChange}
              id={formKeys.acknowledgement}
              required={true}
            />
            I acknowledge that I must pay an amount of income / capital gains
            tax at least equal to the tax the charity reclaims on my donations in the tax year. 
            I agree to notify BEHT on the occassion that I no longer pay sufficient tax 
            or any other details in this form change.
          </label>
        </div>
        <input type="hidden" name={formKeys.currentDate} />
        <input type="hidden" name={formKeys.donationDeclarations} />
        <Recaptcha
          ref="recaptcha"
          sitekey={RECAPTCHA_KEY}
          onChange={this.handleRecaptcha}
        />
        <div className="field mt-1">
          <button className="button is-link" type="submit" disabled={!this.canSubmit()}>
            Submit
          </button>
        </div>
      </form>
    )
  }
}

